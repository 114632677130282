





















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { format } from 'date-fns'

@Component
export default class Lists extends Vue {
  @Prop() private msg!: string
  @Prop() private items!: object[]

  close (event: any, row: any) {
    return undefined
  }

  formatDate (date: string) {
    return format(new Date(date), 'yyyy-MM-dd hh:mm a')
  }

  itemClick (id: string) {
    this.$router.push({ name: 'list-detail', params: { id } })
  }

  complete (id: string) {
    this.$emit('completed', id)
  }
}
