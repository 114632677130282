























import { Component, Vue } from 'vue-property-decorator'
import { getAllLists, createList, setListAsDone } from '@/backend'
import Lists from '@/components/Lists.vue'

// FIXME: Define this type with all its fields
interface Item {
  name: string;
}

@Component({
  components: {
    Lists
  }
})
export default class Home extends Vue {
  items: Item[] = [];
  dialog: boolean = false;
  listName: string = '';

  created () {
    this.getLists()
  }

  async getLists () {
    this.items = await getAllLists()
  }

  async addList () {
    await createList(this.listName, true)
    this.getLists()
    this.listName = ''
  }

  async completeList (id: string) {
    await setListAsDone(id)
    this.getLists()
  }
}
